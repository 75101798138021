<template>
  <div class="container-fluid footer py-5">
    <div class="container py-5">
      <div class="row g-5">
        <div class="col-md-6 col-lg-6 col-xl-3">
          <div class="footer-item d-flex flex-column">
            <h4 class="mb-4 text-white">Get In Touch</h4>
            <a href=""
              ><i class="fas fa-home me-2"></i> Kigali RWANDA</a
            >
            <a href=""><i class="fas fa-envelope me-2"></i> Classicompasstour@gmail.com</a>
            <a href=""><i class="fas fa-phone me-2"></i> (+250) 783 651 525</a>
            <a href="" class="mb-3"
              ><i class="fas fa-print me-2"></i> (+250) 783 651 525</a
            >
            <div class="d-flex align-items-center">
              <i class="fas fa-share fa-2x text-white me-2"></i>
              <a class="btn-square btn btn-success  rounded-circle mx-1" href=""
                ><i class="fab fa-facebook-f"></i
              ></a>
              <a class="btn-square btn btn-success  rounded-circle mx-1" href=""
                ><i class="fab fa-twitter"></i
              ></a>
              <a class="btn-square btn btn-success  rounded-circle mx-1" href=""
                ><i class="fab fa-instagram"></i
              ></a>
              <a class="btn-square btn btn-success  rounded-circle mx-1" href=""
                ><i class="fab fa-linkedin-in"></i
              ></a>
            </div>
          </div>
        </div>
        <div class="col-md-6 col-lg-6 col-xl-3">
          <div class="footer-item d-flex flex-column">
            <h4 class="mb-4 text-white">Company</h4>
            <a href=""><i class="fas fa-angle-right me-2"></i> About</a>
            <a href=""><i class="fas fa-angle-right me-2"></i> Careers</a>
            <a href=""><i class="fas fa-angle-right me-2"></i> Blog</a>
            <a href=""><i class="fas fa-angle-right me-2"></i> Press</a>
            <a href=""><i class="fas fa-angle-right me-2"></i> Gift Cards</a>
            <a href=""><i class="fas fa-angle-right me-2"></i> Magazine</a>
          </div>
        </div>
        <div class="col-md-6 col-lg-6 col-xl-3">
          <div class="footer-item d-flex flex-column">
            <h4 class="mb-4 text-white">Support</h4>
            <a href=""><i class="fas fa-angle-right me-2"></i> Contact</a>
            <a href=""><i class="fas fa-angle-right me-2"></i> Legal Notice</a>
            <a href=""
              ><i class="fas fa-angle-right me-2"></i> Privacy Policy</a
            >
            <a href=""
              ><i class="fas fa-angle-right me-2"></i> Terms and Conditions</a
            >
            <a href=""><i class="fas fa-angle-right me-2"></i> Sitemap</a>
            <a href=""><i class="fas fa-angle-right me-2"></i> Cookie policy</a>
          </div>
        </div>
        <div class="col-md-6 col-lg-6 col-xl-3">
          <div class="footer-item">
            <div class="row gy-3 gx-2 mb-4">
              <div class="col-xl-6">
                <form>
                  <div class="form-floating">
                    <select class="form-select bg-dark border" id="select1">
                      <option value="1" disabled>Language we Use</option>
                      <option value="1">English</option>
                      <option value="1">French</option>
                      <option value="2">German</option>
                      <option value="3">Portugal</option>
                    </select>
                    <label for="select1">English</label>
                  </div>
                </form>
              </div>
              <div class="col-xl-6">
                <form>
                  <div class="form-floating">
                    <select class="form-select bg-dark border" id="select1">
                      <option value="1">USD</option>
                      <option value="2">RWF</option>
                    </select>
                    <label for="select1">$</label>
                  </div>
                </form>
              </div>
            </div>
            <h4 class="text-white mb-3">Payments We Use</h4>
            <div class="footer-bank-card">
              <a href="#" class="text-white me-2"
                ><i class="fab fa-cc-visa fa-2x"></i
              ></a>
              <a href="#" class="text-white me-2"
                ><i class="fas fa-credit-card fa-2x"></i
              ></a>
              <a href="#" class="text-white me-2"
                ><i class="fab fa-cc-mastercard fa-2x"></i
              ></a>
              <a href="#" class="text-white me-2"
                ><i class="fab fa-cc-paypal fa-2x"></i
              ></a>
              <a href="#" class="text-white"
                ><i class="fab fa-cc-discover fa-2x"></i
              ></a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="container-fluid copyright text-body py-4">
    <div class="container">
      <div class="row g-4 align-items-center">
        <div class="col-md-6 text-center text-md-end mb-md-0">
          <i class="fas fa-copyright me-2"></i
          ><a class="text-white" href="#">Classic Compass Tour</a>, All right
          reserved.
        </div>
        <div class="col-md-6 text-center text-md-start"></div>
      </div>
    </div>
  </div>
  <!-- Copyright End -->

  <!-- Back to Top -->
  <a
    href="#"
    class="btn btn-success  btn-success -outline-0 btn-md-square back-to-top"
    ><i class="fa fa-arrow-up"></i
  ></a>
</template>
